.internal-page-layout {
  position: fixed;
  inset: 0 0 0 0;
  background-color: $dirty-white;
  z-index: 300;

  .internal-page-topbar {
    height: $topbar-height;
    background-color: $primary;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
    color: white;
    font-weight: $font-weight-semibold;
    border-bottom: 1px solid $gray-3;

    .back {
      font-size: 18px;
      margin-right: 8px;
    }
  }
  .internal-page-content {
    position: relative;
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 40px;
  }
}
