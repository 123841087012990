#reward-details {
  .accordion-item {
    border: none;
    .accordion-header {
      .accordion-button {
        padding: 0;
        box-shadow: none;
        &::after {
          margin-left: 10px;
          margin-right: auto;
        }
      }
    }
    .accordion-body {
      padding: 0;
    }
  }
}
