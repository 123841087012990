.highlighted-item {
  position: relative;
  border: 1px solid $primary;
  border-radius: 15px;
  padding: 5px 8px;
  margin-top: 10px;
  margin-left: -9px;
  margin-right: -9px;

  &.border-success {
    border-color: $success;
  }

  .item-label {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    height: 20px;

    span {
      font-size: 10px;
      padding-left: 10px;
      padding-right: 10px;
      background-color: white;
      line-height: 10px;
    }
  }
}
