.objective {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  p {
    color: $figma-gray;
    font-size: 0.8rem;
    margin-bottom: 0;
  }

  &.active {
    & > .objective-icon {
      border-color: $primary;
      background-color: #FEFAE7;
      border-style: solid;
    }
  }

  .objective-icon {
    padding-left: 4px;
    padding-right: 4px;
    width: 60px;
    height: 28px;
    border-radius: 20px;
    color: $figma-gray;
    border: 1px dotted $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.6rem;
    font-weight: 700;
  }

  svg {
    color: $figma-gray;
    height: 1.1rem;
    width: 1.1rem;
  }
}
.objectives-box {
  i {
    font-size: 1.5rem;
  }
}
.objective-lock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.5);
}
