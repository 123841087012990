#banner-entry-bonus {
  margin: 0 32px 24px 32px;

  .card {
    border-radius: 4px;
    border-style: solid;
    border-width: 2px;
    border-color: $success;
    text-align: center;
    max-width: 300px;
    margin: auto;
  }

  h5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  small {
    font-size: 16px;
    font-weight: 400;
  }
}
