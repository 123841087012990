#faq {

  .accordion-item {
    border-radius: 5px;
    border: 1px solid $gray-3;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .accordion-body {
      padding-top: 0;
    }

    .accordion-button {
      padding: 12px 16px 12px 10px;
      line-height: 120%;

      &:not(.collapsed) {
        color: inherit;
        background-color: inherit;
        box-shadow: inherit;
      }
    }
  }
}

#how-rewards-work {
  .faq-element {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .faq-number {
      display: flex;
      justify-content: end;
      align-items: start;
      margin-right: 0.5rem;
      font-size: $h6;
      max-width: 14px;
      flex: 0 0 10%;

      span {
        line-height: 100%;
      }
    }
    .faq-content {
      display: flex;
      flex-direction: column;

      .faq-title {
        font-size: $body-small;
        color: $gray-4;
      }
      .faq-text {
        font-size: $body-regular;
      }
    }
  }
}
#who-pays-for-rewards {
  text-align: center;

  p {
    text-align: left;
  }
  img {
    width: 100%;
    max-width: 300px;
    margin-bottom: 24px;
  }
}
