/*
  GLOBAL
*/

.modal {
  .card {
    border-radius: $card-border-radius;

    .card-body {
      padding: $card-padding;
    }
  }
}

.card:has(.card-header) {
  & > .card-body {
    padding-top: 0;
  }

  & > .card-header {
    padding: 24px 24px 0 24px;
    border-radius: $card-border-radius;
    background-color: $card-bg-color;
  }
}


.card {
  border-radius: 0;
  background-color: $card-bg-color;
  box-shadow: $box-shadow;
  margin-bottom: 24px;

  @include media-breakpoint-up(md) {
    border-radius: $card-border-radius;
  }

  &.bordered-red {
    border: 1px solid $primary;
    box-shadow: 0 4px 4px rgba(234, 107, 36, 0.25);
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.card-short > .card-body {
    line-height: 1rem;
    padding: 8px 20px;
  }

  h3.title {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
    line-height: 1.4rem;
    color: inherit !important;
  }

  .card-body {
    padding: 24px;
  }

  .subtitle {
    text-align: center;
    font-weight: 400;
    color: $figma-gray;
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-top: 15px;

    strong {
      color: $figma-gray-dark;
    }
  }
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

.card-contest {

  h4 {
    font-size: 0.8rem;
    font-weight: 700;
  }

  h6 {
    font-size: 0.65rem;
    color: $figma-gray;
    margin-top: 15px;
    font-weight: 400;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  button {
    color: $primary;
    padding: 5px 15px;
    margin-bottom: 5px;
    font-size: 0.8rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }


  .prize {

    .disabled {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 5px;

      svg {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: #dedede;
        z-index: 1000;
        opacity: 0.7;
        color: #aaa;
      }
    }

    i {
      font-size: 0.8rem;
    }

    .text {
      font-size: 1.5rem;
      color: $primary;
      font-weight: 700;
    }
  }
}

.countdown {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 2rem;

  &.countdown-bordered {
    border-radius: 10px;
    border: 1px solid #FFECC7;

    &.text-secondary {
      border-color: $secondary;
    }
  }

  &.countdown-compact {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 15px;
    font-size: 1.25rem;
    background-color: #FEFAE7;
    margin-top: 4px;

    &.countdown-small {
      font-size: 14px;
      padding: 2px 15px;

      & > span {
        font-size: 14px;
      }

      & > svg {
        width: 14px;
        height: 14px;
      }
    }

    span {
      margin: 2px 8px 0 8px;
    }

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 10px;
    }
  }
}

.card-network {
  width: 100%;

  h5 {
    color: $figma-gray-dark;
    font-weight: 400;
  }

  .row-stats {
    padding: 0 6px;
    margin: 6px 0;

    @include media-breakpoint-up(lg) {
      padding-top: 7px;
      padding-bottom: 7px;
    }

    h5 {
      color: $figma-gray;
    }

    button {
      display: flex;
      align-items: center;
      margin: auto;
    }

  }
}

.card-share {

  &.card-share-compressed {
    h5 {
      font-size: 0.9rem;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }

    .container-button-share {
      margin-bottom: 0;
    }
  }

  h5 {
    text-align: center;
    font-weight: 700;
    color: $figma-gray-dark;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    font-weight: 400;
    color: $figma-gray;
    font-size: 0.7rem;
    line-height: 1.1rem;
    margin-top: 15px;

    strong {
      color: $figma-gray-dark;
    }
  }
}

.container-button-share {
  display: flex;
  justify-content: space-evenly;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 0;

  a, button {
    display: flex;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    i {
      font-size: 24px;
    }
  }
}

.link-share {
  justify-content: center;

  button {
    text-align: center;
  }

  span {
    color: $figma-gray;
    font-size: 0.65rem;
  }
}

.card-score {
  padding: 0;

  .card-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  span {
    color: $figma-gray;
    font-size: 0.8rem;
  }

  h5 {
    color: $primary;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 700;
  }

}

.card-people {

  h4 {
    font-size: 0.9rem;
    margin-top: 16px;
  }

  .achievement-box {
    margin-top: 13px;

    .achievement-step {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      p {
        color: $figma-gray-light;
        margin-left: 10px;
        font-size: 0.8rem;

        span.milestone {
          color: $primary;
        }
      }

      &.active {
        & > .achievement-icon {
          border-color: $primary;
          background-color: #FEFAE7;
          border-style: solid;
        }

        & > p {
          color: $figma-gray-dark;
        }
      }

      .achievement-icon {
        width: 56px;
        height: 28px;
        border-radius: 20px;
        color: $figma-gray;
        border: 1px dotted $primary;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 700;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }
}

.card-contest-rules {
  h4 {
    font-size: 0.9rem;
    margin-top: 16px;
  }

  p {
    color: $figma-gray-light;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 0.7rem;
    text-align: left;

    span.milestone {
      color: $primary;
    }
  }

  .rule-box {
    margin-top: 13px;

    .rule-step {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 15px;


      &.active {
        & > .rule-icon {
          border-color: $primary;
          background-color: #FEFAE7;
          border-style: solid;
          color: $figma-gray;
        }

        & > p {
          color: $figma-gray-dark;
        }
      }

      .rule-icon {
        flex: 0 0 50px;
        height: 28px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 20px;
        color: $figma-gray-light;
        border: 1px dotted $primary;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 0.9rem;
        font-weight: 700;

        svg {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }
}

.card-participants {
  h6 {
    font-size: 0.8rem;
    margin-bottom: 0;
    color: $figma-gray;

    i {
      margin-left: 10px;
    }
  }

  .accordion-body {
    margin-top: 16px;
  }
}

.card-list-participants {
  ul {
    margin-bottom: 0;
    list-style-type: none;
    margin-top: 13px;

    li {
      text-align: left;
      font-size: 0.8rem;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      svg {
        height: 1rem;
        width: 1rem;
        margin-right: 10px;
      }
    }
  }
}

.card-referral {

  .card-referral-content {
    width: 75%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    h6 {
      font-size: 0.8rem;
      margin-bottom: 0;
      color: $figma-gray;
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.card-top-features {
  h4 {
    font-size: 0.9rem;
    margin-top: 16px;
  }

  .achievement-box {
    margin-top: 13px;

    .achievement-step {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 15px 0;

      p {
        color: $figma-gray-light;
        margin-left: 10px;
        font-size: 0.8rem;
        margin-bottom: 0;
      }

      &.active {
        & > .achievement-icon {
          border-color: $primary;
          background-color: #FEFAE7;
          border-style: solid;
        }

        & > div > p {
          color: $figma-gray-dark;
        }
      }

      .achievement-icon {
        width: 70px;
        height: 28px;
        border-radius: 20px;
        color: $figma-gray;
        border: 1px dotted $primary;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 0.6rem;
        font-weight: 700;
      }

      svg {
        color: $figma-gray-light;
        margin-left: 10px;
        height: 1.1rem;
        width: 1.1rem;
      }
    }
  }
}

.card-no-need-cv {
  line-height: 1.2rem;
  background-color: #FEFAE5;

  p {
    margin: 10px 0 0 0;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 400;
    color: $figma-gray;
  }
}

.card-congratulations {
  margin-top: 85px;

  .card-body {
    padding: 30px 16px;

    h4 {
      font-size: 1.7rem;
    }

    i {
      font-size: 64px;
      margin: 15px 0;
    }

    h5 {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
      margin-bottom: 20px;
    }

    button {
      width: 100%;
      font-size: 1rem;
    }

    h6 {
      text-align: center;
      color: $figma-gray;
      font-size: 0.75rem;
      margin-top: 10px;
    }
  }
}

.card-reward {
  width: 100%;
  align-self: center;
  padding: 0;
  font-style: normal;

  h3.title {
    font-weight: 400;
  }

  strong {
    color: $figma-gray-dark;
    font-weight: 700;
    font-size: 1rem;
  }

  .card-body {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  em {
    color: $primary;
    font-size: 12px;
    padding: 0 8px;
  }
}

.banner-countdown {
  background-color: $primary-yellow;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid $primary-orange;
}

.card-reward-detail {
  .card-body {
    padding-right: 0;
  }
}

$ambassadorLevelStepWidth: 130px;
$ambassadorLevelStepWidthMd: 200px;
$ambassadorLevelBarLastItem: 30px;
$ambassadorLevelBarLastItemMd: 100px;
.card-ambassador {
  .ambassador-level-container {
    border: 1px solid $gray-2;
    border-radius: 8px;
    padding: 20px 28px;
    overflow-x: auto;


    .progress {
      width: calc(100% - #{$ambassadorLevelStepWidth} + #{$ambassadorLevelBarLastItem});
      @include media-breakpoint-up(md) {
        width: calc(100% - #{$ambassadorLevelStepWidthMd} + #{$ambassadorLevelBarLastItemMd});
      }
    }

    .ambassador-level-step {
      width: $ambassadorLevelStepWidth;
      display: inline-block;
      min-height: 20px;
      @include media-breakpoint-up(md) {
        width: $ambassadorLevelStepWidthMd;
      }

      &:last-of-type {
        width: calc(#{$ambassadorLevelStepWidth} - #{$ambassadorLevelBarLastItem});
        @include media-breakpoint-up(md) {
          width: calc(#{$ambassadorLevelStepWidthMd} - #{$ambassadorLevelBarLastItemMd});
        }

      }

      &.percentage {
        color: $gray-4;
        text-align: center;

        &.active {
          color: $gray-6;
          font-weight: $font-weight-medium;

          .with-bonus {
            color: $gray-6;
          }

          .level-step-value {
            .without-bonus {
              text-decoration: line-through;
              color: $gray-4;
            }
          }
        }

        .top-value {
          margin-left: -50%;
          width: fit-content;
        }
      }

      .with-bonus {
        margin-bottom: 2px;
        color: $gray-4;
      }

      .level-step-value {
        font-weight: $font-weight-normal;

        .without-bonus {
          font-size: $small;
          text-decoration: line-through;
          color: $gray-3;
        }
      }

      &.step {
        margin-left: -5px;
        margin-right: 5px;
        padding-top: 12px;

        .number {
          color: $gray-4;
        }

        .invites {
          margin-left: 3px;
          text-transform: uppercase;
          font-size: 16px;
          opacity: 0.5;
        }

        .ambassador-label {
          margin-top: 2px;
          font-weight: $font-weight-medium;
          font-size: $h8;
          color: $gray-3;
        }

        &.active {
          .number {
            color: $gray-6;
            font-size: $h6;
            font-weight: $font-weight-semibold;
          }

          .ambassador-label {
            color: $gray-6;
            font-weight: $font-weight-medium;
          }

          .invites {
            opacity: 1;
          }
        }
      }
    }
  }
}

.card-referral-graph {
  .graphReferrals {
    height: 200px;
    padding: 0 16px 0 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
      height: 350px;
    }
  }
  .number {
    width: 100px;
  }
  .ct-point {
    stroke-width: 0 !important;
  }

  .ct-line {
    stroke-width: 3px !important;
  }

  .ct-series-a {
    .ct-line, .ct-point {
      stroke: $info !important;
    }
  }

  .ct-series-b {
    .ct-line, .ct-point {
      stroke: $primary !important;
    }
  }

  .ct-series-c {
    .ct-line, .ct-point {
      stroke: $success !important;
    }
  }
}

.card-rank-leaderboard {
  .box {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: #F5F5F5;
    border-radius: 4px;
  }
}

.card-monitor-invites {
  .box-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .column {
      &:first-of-type > .box {
        margin-left: 0;
      }

      &:last-of-type > .box {
        margin-right: 0;
      }
    }

    .box {
      display: flex;
      flex-direction: column;
      flex: 1 1 0;
      padding: 12px;
      background-color: #F5F5F5;
      border-radius: 4px;
      margin: 0 4px;
      height: 100%;

      .count {
        font-size: 32px;
        font-weight: $font-weight-medium;
        margin-bottom: 6px;
        line-height: 100%;
      }

      .text {
        font-size: 12px;
        color: $gray-4;
        font-weight: $font-weight-normal;
        line-height: 120%;
      }
    }
  }

  .table-benefits {
    margin: 8px 0;

    td {
      padding: 4px;
      vertical-align: top;

      &:first-of-type {
        padding-left: 0;
        text-align: center;
      }

      small {
        font-size: 14px;
        font-weight: $font-weight-normal;
        color: $gray-4;
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }
}

.card-feeds-preview {
  hr {
    margin: 12px 0 12px 0;
  }

  .feeds-list {
    position: relative;
    padding-bottom: 8px;

    .feed {
      margin-top: 18px;

      .type {
        font-size: 12px;
        color: $gray-5;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;
      }

      .title {
        font-size: 16px;
        font-weight: $font-weight-medium;
        color: $gray-6;
        line-height: 100%;
      }

      .content {
        font-size: 16px;
        color: $gray-6;
        font-weight: $font-weight-normal;
        line-height: 100%;
      }
    }

    .feeds-blurred {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 45px;
      background: linear-gradient(180deg, transparent 0, $card-bg-color 100%);
      border-bottom: 1px solid rgba(61, 60, 60, 0.26);
    }

    &.feeds-list-complete {
      margin-top: 16px;
      .feed {
        padding: 16px 24px;
        border-bottom: 1px solid rgba(61, 60, 60, 0.15);
        margin: 0;

        .type {
          color: $gray-4;
          margin-bottom: 4px;
        }

        .content {
          line-height: 120%;
        }

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}




