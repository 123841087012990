.install-prompt {
  position: fixed;
  left:0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

  img {
    border-radius: 15px;
    width: 50px;
    margin-right: 15px;
  }
  span {
    color: $figma-gray;
  }
  button {
    margin-left: 15px;
    display: flex;
    align-items: center;
  }
}
