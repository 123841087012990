.qr-code-box {
  display: flex;
  justify-content: flex-end;

  #QrCode {
    z-index: 1000;
  }


  span {
    margin-right: 10px;
    color: white;
    font-size: 1.3rem;
    text-align: right;
  }

  svg {
    color: white;
    height: 60px;
    width: 60px;
  }
}
.qr-code-tooltip {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background-color: rgba(0,0,0,0.95);
  z-index: 900;
  transform: none !important;
  display: flex;
  padding-top: 25px;
  justify-content: center;

  .tooltip-inner {
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    max-width: none;
    height: fit-content;

    .qr-code-container {
      display: flex;
      flex-direction: column;

      small {
        font-size: 0.9rem;
      }

      svg {
        height: 362px !important;
        width: 362px !important;
        padding: 5px;
        background-color: rgba(255,255,255,1);
      }
    }

    span {
      color: white;
      font-size: 2rem;
    }
  }
}
