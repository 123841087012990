//
// Google font - Poppins
//


.fa-1-5x {
  font-size: 1.5em;
}
.fa-2-5x {
  font-size: 2.5em;
}
.font-weight-light {
  font-weight: 100 !important;
}
.font-weight-lighter {
  font-weight: 300 !important;
}
.font-weight-regular {
  font-weight: 400 !important;
}
.font-weight-bolder {
  font-weight: 500 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
