.popover-share {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
  border: 1px solid $primary;

  &:before {
    position: absolute;
    left: calc(50% - 7px);
    bottom: -7px;
    height: 14px;
    width: 14px;
    transform: rotate(45deg);
    background-color: white;
    content: '';
    border: 1px solid $primary;
    border-top: none;
    border-left: none;
    margin-bottom: -1px;
  }

  .popover-body {
    text-align: center;
    color: $primary;
    font-size: 0.8rem;

    span {
      color: $figma-gray;
      font-size: 0.6rem;
    }
  }
}
