//
// backgrounds.scss
//

@each $color, $value in $theme-colors {
  .bg-#{$color} {
    &.bg-soft {
      background-color: rgba(($value), 0.25) !important;
    }
  }
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.2 !important;
}

.opacity-2 {
  opacity: 0.4 !important;
}

.opacity-3 {
  opacity: 0.6 !important;
}

.opacity-4 {
  opacity: 0.8 !important;
}

.opacity-5 {
  opacity: 1 !important;
}

.bg-blurred {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

/* Maybe even support hover opacity shifts */
.opacity-2h5 {
  opacity: 0.4 !important;
  transition: opacity 0.25s ease-in-out !important;
  -moz-transition: opacity 0.25s ease-in-out !important;
  -webkit-transition: opacity 0.25s ease-in-out !important;
}

.opacity-2h5:hover {
  opacity: 1 !important;
}

.opacity-0h5 {
  opacity: 0 !important;
  transition: opacity 0.25s ease-in-out !important;
  -moz-transition: opacity 0.25s ease-in-out !important;
  -webkit-transition: opacity 0.25s ease-in-out !important;
}

.opacity-0h5:hover {
  opacity: 1 !important;
}
