#topbar {
  background-color: $primary;
  height: calc(#{$topbar-height} + #{$topbar-menu-height} / 2);
  padding-bottom: calc(#{$topbar-menu-height} / 2);
  margin-bottom: calc(#{$topbar-menu-height} / 2 + 16px);
  z-index: 200;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  .container-topbar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 24px;

    .topbar-countdown {
      padding: 5px 15px;
      background-color: $success;
      color: white;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
    }

    .logo {
      width: 32px;
      height: 32px;
      color: white;
    }

    .logo-text {
      margin-left: 10px;
      font-size: 18px;
      line-height: 150%;
      font-weight: 600;
      color: white;
    }
  }

  .container-topbar-menu {
    position: absolute;
    bottom: calc(-#{$topbar-menu-height} / 2);
    left: 0;
    right: 0;
    height: $topbar-menu-height;
    z-index: 250;

    @include media-breakpoint-up(md) {
      padding-left: calc(#{$grid-gutter-width} * .5);
      padding-right: calc(#{$grid-gutter-width} * .5);
    }

    .navigation {
      background-color: white;
      border-radius: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 24px;
      box-shadow: $box-shadow;


      .nav-item {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;
        flex: 0 0 33.3%;
        text-align: center;
        color: $gray-2;

        &.active {
          font-size: 18px;
          color: $gray-6;

          span {
            margin-bottom: -2px;
            border-bottom: 2px solid $gray-6;
          }
        }
      }
    }
  }
}
