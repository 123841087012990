.modal-footer {
  &.button-divided {
    justify-content: space-between;
  }
}

.modal {
  .modal-body {
    .caption, .caption-small {
      color: $figma-gray-dark;
    }
    .card {
      .caption, .caption-small {
        color: inherit;
      }
    }
  }
}
.modal-backdrop-darker {
  &.modal-backdrop {
    &.show {
      opacity: 0.93;
    }
  }
}
.backdrop-content {
  margin: auto;
  .modal-content {
    padding: 24px;
    background: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
  }
}

.modal-mobile-full {
  display: flex;
  inset: 0;
  padding-top: 4rem;

  .modal-dialog {
    height: 100%;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    z-index: 1000;

    .modal-content {
      border-radius: 24px 24px 0 0;
      background: rgba(255, 252, 250, 0.1);
      backdrop-filter: blur(300px);
      height: 100%;
      display: inline-table;
      -ms-overflow-style: none;
      border-width: 0;

      .title {
        color: white;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
      }

      .title-big {
        color: white;
        font-weight: 500;
        margin-bottom: 16px;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .gift-box {
        position: absolute;
        left: 0;
        width: fit-content;
        display: flex;
        margin-left: 1.5rem;
        border-radius: 16px;
        background-color: white;
        color: $primary;
        font-size: 1.5rem;
        padding: 1rem;
      }

      .box-withdraw {
        h5 {
          color: $primary;
          font-size: 1.8rem;
          margin-bottom: 0;
          font-weight: 700;

          small {
            font-size: 1rem;
          }
        }

        h3 {
          margin-top: 2rem;
          font-size: 1.7rem;
          color: $figma-gray-dark;
          font-weight: 700;
        }

        button.close {
          font-size: 14px;
          padding: 1.8rem;
        }

        button {
          font-size: 0.875rem;
        }
      }
    }
  }

}

.modal-mobile-center {
  .modal-dialog {
    display: flex;
    z-index: 1000;

    .modal-content {
      border-radius: 4px;
      background-color: $white;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      border-width: 0;

      .modal-body {
        padding: 24px;

        h1 {
          font-size: 3rem;
        }

        .text {
          color: $figma-gray;
        }
      }
    }
  }
}

.modal-mobile-full, .modal-mobile-center {
  .modal-body {
    h4.title {
      color: $figma-gray-dark;
      margin-top: 30px;
    }
  }
}

// Fade from bottom
.modal.modal-mobile-full.fade .modal-dialog {
  //transform: translate3d(0, 75vh, 0);
  transform: translateY(75vh);
  -webkit-transform: translateY(75vh);
  -ms-transform: translateY(75vh);
  -moz-transform: translateY(75vh);
  -o-transform: translateY(75vh);
}

.modal.modal-mobile-full.show .modal-dialog {
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
}
