.wm-100 {
  max-width: 100px;
}

.wm-200 {
  max-width: 200px;
}

.wm-300 {
  max-width: 300px;
}

.wm-400 {
  max-width: 400px;
}

.wm-500 {
  max-width: 500px;
}

.w-35 {
  width: 35% !important;
}

.w-85 {
  width: 85% !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

a[disabled],
.disabled-link {
  pointer-events: none;
}

.top-right-icon {
  border: 0;
  font-size: 14px;
  padding: 9px;
  background: transparent no-repeat url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 0 15 15' fill='%3D3C3C'%3e%3cpath fill-rule='evenodd' d='M7.67678 6.67251L7.67674 6.67248L1.95407 0.951904C1.68477 0.682699 1.22132 0.682699 0.952016 0.951904C0.682661 1.22116 0.682661 1.6846 0.952016 1.95385L6.49781 7.49762L0.952016 13.0414C0.682661 13.3106 0.682661 13.7741 0.952016 14.0433C1.09919 14.1905 1.2796 14.25 1.45304 14.25C1.62649 14.25 1.8069 14.1905 1.95407 14.0433L7.67674 8.32276L7.67678 8.32273C7.93193 8.06757 8.125 7.80499 8.125 7.49762C8.125 7.19025 7.93193 6.92766 7.67678 6.67251Z'/%3e%3c/svg%3e");
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  z-index: 2;
  span {
    display: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.w-max {
  width: max-content;
}
.w-content {
  width: fit-content;
}
