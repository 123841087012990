#tutorial-claim {
  z-index: 1000;
  background-color: $white;
  button {
    z-index: 1100;
  }
}
.vote {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: $gray-4;
  background-color: $white;
  padding: 1rem 0.5rem;
  margin: 4px 0;

  @include media-breakpoint-up(sm) {
    padding: 0.5rem 1rem;
  }

  &.vote-unlocked {
    border-color: $primary;
  }

  &.vote-secured {
    border-color: $success;
  }

  &.vote-secured-hired {
    border-color: $success;
    border-width: 2px;
    margin-left: -1px;
  }

  &.vote-redeemed {
    border-color: $secondary-dark;
  }

  &.vote-first-unlock {
    border-color: $danger;
  }

  .vote-user {
    position: relative;
    display: flex;
    align-items: center;

    .vote-name {
      display: flex;
      flex-direction: column;
      padding-left: 0.5rem;

      small {
        line-height: 100%;
      }
    }
  }

  .vote-cta {
    display: flex;
    align-items: center;
    flex-direction: column;

    h5 {
      padding-top: 4px;
    }
  }
}


