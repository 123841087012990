// Headings

h1, .h1 {
  font-size: $h1;
  line-height: $line-height;
  font-weight: $font-weight-bold;
  font-family: $font-family-poppins;
}

h2, .h2 {
  font-size: $h2;
  line-height: $line-height;
  font-weight: $font-weight-semibold;
  letter-spacing: -0.015em;
  font-family: $font-family-poppins;
}

h3, .h3 {
  font-size: $h3;
  line-height: $line-height;
  font-weight: $font-weight-semibold;
  font-family: $font-family-poppins;
}

h4, .h4 {
  font-size: $h4;
  line-height: $line-height;
  font-weight: $font-weight-semibold;
  font-family: $font-family-poppins;
}

h5, .h5 {
  font-size: $h5;
  line-height: $line-height;
  font-weight: $font-weight-semibold;
  font-family: $font-family-poppins;
}

h6, .h6 {
  font-size: $h6;
  line-height: $line-height;
  font-weight: $font-weight-semibold;
  font-family: $font-family-poppins;
}

.h7 {
  font-size: $h7;
  line-height: $line-height;
  font-weight: $font-weight-medium;
  font-family: $font-family-poppins;
}

.h8 {
  font-size: $h8;
  line-height: $line-height;
  font-weight: $font-weight-medium;
  font-family: $font-family-poppins;
}

.b1 {
  font-size: $h8;
  font-weight: $font-weight-normal;
}

.gray-2 {
  color: $gray-2 !important;
}

.gray-3 {
  color: $gray-3 !important;
}

.gray-4 {
  color: $gray-4 !important;
}

.gray-5 {
  color: $gray-5 !important;
}

.gray-6 {
  color: $gray-6 !important;
}

.orange-light {
  color: $orange-light !important;
}

.bg-orange-light {
  background-color: $orange-light !important;
}

small, .small {
  font-size: $small;
}

.body-small {
  font-size: $body-small;
}

.body-regular {
  font-size: $body-regular;
}

.fw-medium {
  font-weight: $font-weight-medium !important;
}

.fw-semibold {
  font-weight: $font-weight-semibold !important;
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

.text-secondary-dark {
  color: $secondary-dark !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.overline {
  font-size: 12px;
  font-weight: $font-weight-normal;
  color: $gray-6;
}


.text-gray-light {
  color: $figma-gray-light !important;
}

.text-gray-normal {
  color: $figma-gray !important;
}

.text-gray-dark {
  color: $figma-gray-dark !important;
}

.bg-primary-yellow {
  background-color: $primary-yellow !important;
}

.text-gold {
  color: #FFD700;
}

.text-silver {
  color: #C0C0C0;
}

.text-bronze {
  color: #CD7F32;
}

.text-primary-dark {
  color: $primary-dark !important;
}

.text-linkedin {
  color: #0E76A8 !important;
}

.bg-primary-dark {
  background-color: $primary-dark !important;
}

.bg-primary-red {
  background-color: #FCEBEC !important;
}

.bg-gray-2 {
  background-color: $gray-2;
}

.bg-gray-3 {
  background-color: $gray-3;
}

.bg-gray-4 {
  background-color: $gray-4;
}

.bg-gray-5 {
  background-color: $gray-5;
}

.bg-gray-6 {
  background-color: $gray-6;
}

.title {
  color: $figma-gray-dark;
  font-weight: $font-weight-bold;
  //font-size: 0.875rem;
  font-size: 0.9rem;
}

.title-big {
  font-size: 1.5rem;
}

.caption {
  color: $figma-gray;
  font-weight: 300;
  //font-size: 0.75rem !important;
  font-size: 0.825rem !important;
}

.caption-small {
  color: $figma-gray;
  font-weight: 300; //normal
  //font-size: 0.625rem !important;
  font-size: 0.65rem !important;
}

.caption-highlight {
  color: $figma-gray-dark;
  font-weight: 300;
  font-size: 0.775rem !important;
}

.caption-big {
  font-size: 1rem;
}

.link {
  color: $secondary;
  font-weight: 300;
  font-size: 0.825rem !important;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.bordered-primary {
  border: 1px solid $primary;
  border-radius: 10px;
}

.bordered-secondary {
  border: 1px solid $secondary;
  border-radius: 10px;
}

.bordered-yellow-light {
  border: 1px solid #FFECC7;
  border-radius: 10px;
}

.bordered-success {
  border: 1px solid $success;
}

.border-thick {
  border-width: 2px;
}
