body {
  color: $gray-6;
  font-weight: $font-weight-normal;
  line-height: 120%;
  font-size: 1rem;
}

small {
  font-size: 0.875rem;
}

.page-content {
  padding-top: calc(#{$topbar-height} + #{$topbar-menu-height} + 16px);
}

.page-content .container-fluid {
  padding: 0;
}

.under-header {
  margin-top: calc(-#{$topbar-menu-height} / 2 - 16px);
  padding-top: 16px;
}

.w-sm-50 {
  @include media-breakpoint-up(sm) {
    width: 50% !important;
  }
}

.w-sm-75 {
  @include media-breakpoint-up(sm) {
    width: 75% !important;
  }
}

.w-sm-100 {
  @include media-breakpoint-up(sm) {
    width: 100% !important;
  }
}
.w-md-50 {
  @include media-breakpoint-up(md) {
    width: 50% !important;
  }
}

.w-md-75 {
  @include media-breakpoint-up(md) {
    width: 75% !important;
  }
}

.w-md-100 {
  @include media-breakpoint-up(md) {
    width: 100% !important;
  }
}

.w-lg-50 {
  @include media-breakpoint-up(lg) {
    width: 50% !important;
  }
}

.w-lg-75 {
  @include media-breakpoint-up(lg) {
    width: 75% !important;
  }
}

.w-lg-100 {
  @include media-breakpoint-up(lg) {
    width: 100% !important;
  }
}
