//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn {
  border-radius: 8px;
  font-weight: $font-weight-bold;
  font-size: 0.875rem;
}
.btn-vote {
  padding: 5.5px 13.5px;
}

// Rounded Buttons
.btn-rounded {
  border-radius: 40px;
}

.btn-primary {
  background-color: $primary;
  color: $white;

  &:disabled {
    background-color: $gray-3;
    color: $gray-4;
  }

  &:hover {
    background: linear-gradient(61.66deg, #EA6B24 23.26%, #FF8E0E 99.31%), $primary;
  }
}

.btn-link {
  background: none;
  color: $secondary;

  &:disabled {
    color: $gray-3;
    &:hover {
      background-color: transparent;
    }
  }
  &:hover {
    background-color: $secondary-light;
  }
}


.btn-dark,
.btn-secondary {
  color: $gray-200 !important;
}

.btn-outline-light {
  color: $gray-900;
}

.btn-outline-dark,
.btn-dark {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:hover {
    color: $light;
  }
}

.btn.btn-white {
  color: $primary;
  border: 1px solid $primary;

  img {
    color: $primary;
  }

  &:hover {
    color: white;
    border-color: $primary-light-hover;
    background-color: $primary-light-hover;

    img {
      color: white;
    }
  }
}

.btn-no-focus {
  button.btn-primary, button.btn-outline-primary {
    &.active {
      border-left-color: white;
      border-right-color: white;
    }
    &:first-of-type {
      &.active {
        border-left-color: $primary;
      }
    }
    &:last-of-type {
      &.active {
        border-right-color: $primary;
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
    &:hover {
      color: $primary;
      background-color: white;
      &.active {
        color: white;
        background-color: $primary;
      }
    }
  }
}
