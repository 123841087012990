//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  .table-light {
    color: $body-color;
    border-color: $table-border-color;
    background-color: $table-head-bg;
  }

  &.table-no-pointer {
    tr:hover {
      cursor: default;
    }
  }
}

table.table-no-pointer {
  tr:hover {
    cursor: default;
  }
}

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }
}

.table-dark > :not(:last-child) > :last-child > * {
  border-bottom-color: $table-dark-border-color;
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9
;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9
;
  content: "";
}

table {
  &.no-caret {
    th .react-bootstrap-table-sort-order,
    th .order {
      display: none;
    }
  }

  tr:hover {
    cursor: pointer;
  }
}

.table-rewards {
  tr {
    &.detail {
      li {
        padding-left: 10px;
      }
    }

    &.detail-2 {
      li {
        padding-left: 30px;
      }
    }

    td, th {
      padding-top: 3px;
      padding-bottom: 3px;
      padding-right: 15px;
      text-align: right;
    }

    td {
      &.border-bottom-total {
        border-bottom: 1px dashed #dedede;
      }

      &:first-of-type {
        text-align: left;
      }

      &:last-of-type {
        text-align: center;
        border-left: 1px solid #dedede;
        padding-right: inherit;
      }
    }
  }
}

.table-leaderboard {
  td {
    padding: 3px !important;
  }
}

#table-referrals {

  .thead .th, .tbody .td, .tfoot .td {
    padding: 8px 2px;
    width: 19%;
    &:first-of-type {
      width: 43%;
      justify-content: start;
    }
  }

  .thead .tr, .tbody .tr {
    border-bottom: 1px solid #E6E5E5;
  }

  .tfoot .tr {
    border-top: 1px solid #E6E5E5;
  }

  .thead, .tbody, .tfoot {
    display: block;
  }

  .tr {
    display: flex;
    flex-direction: row;

    .td, .th {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  .thead {
    .th {
      font-weight: $font-weight-normal;
      text-transform: uppercase;
    }
  }

  .tbody {
    position: relative;
    max-height: 200px;
    overflow-y: scroll;
    display: block;
  }
}
