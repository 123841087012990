#flow-unlock-vote {
  color: $white;

  .flow-title {
    line-height: 100%;
  }

  button {
    z-index: 100;
  }

  #vote-again {
    color: $gray-6;

    .table-votes {
      .table-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 6px 8px;

        &.th {
          text-transform: uppercase;
        }

        &.current {
          border: 1px solid $danger;
          color: $danger;
        }

        .table-name {
          width: 70%;
        }

        .table-availability {
          text-align: center;
          width: 30%;
        }

        &.bg {
          border-radius: 2px;
          background-color: #F5F5F5;
          margin-bottom: 4px;
        }
      }
    }
  }
}
