#page-scroll {

  h1 {
    font-size: 3rem;

    &.page-title {
      text-align: center;
      color: white;
      font-size: 2rem;
      font-weight: 500;
    }

  }

  .form-group {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  input, .autocomplete__placeholder, .autocomplete__input, .autocomplete__single-value, .autocomplete__menu {
    font-family: inherit !important;
    font-size: 0.85rem !important;
    text-align: left !important;
  }

  input, .autocomplete__input, .autocomplete__single-value {
    color: $gray-800;
  }

  .z-index-100 {
    z-index: 100 !important;
  }

  .autocomplete__menu {
    z-index: 100;

    mark {
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;
      color: $primary;
    }
  }

  .card {
    position: relative;
    color: $gray-6;

    &.card-shadow {
      box-shadow: 3px 3px 5px -2px #ddd;
    }
  }

  a.no-link:hover {
    cursor: inherit !important;
  }

  .card-votes {
    padding-top: 0;
    padding-bottom: 0;

    h5 {
      font-size: 0.7rem;
      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }
    }

    .mini-stats-wid {
      background: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-up(sm) {
        background: inherit;
        box-shadow: inherit;
        padding-left: inherit;
        padding-right: inherit;
      }

      h5 {
        font-size: 0.8rem;
        @include media-breakpoint-up(sm) {
          font-size: 1rem;
        }
      }

      .card-body {
        padding-left: 0;
        padding-right: 0;
        @include media-breakpoint-up(sm) {
          padding-left: inherit;
          padding-right: inherit;
        }


        .avatar-title i {
          font-size: 18px;
          @include media-breakpoint-up(sm) {
            font-size: 24px;
          }
        }
      }
    }
  }

}
