.certificate-download-icon {
  opacity: 0.6;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2rem !important;

  &:hover {
    cursor: pointer;
    opacity: 0.95;
  }
}
